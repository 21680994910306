import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const userProfile = this.authService.userProfileExists();

    if (!userProfile) {
      this.router.navigate(['accounts/login']);
      localStorage.clear();
      return false;
    }

    const allowedRoles: number[] = route.data.allowedRoles;

    if (allowedRoles === undefined) {
      return true;
    }

    const roles = this.authService.getUserRols();

    if (roles === null) {
      this.router.navigate(['accounts/login']);
      localStorage.clear();
      return false;
    }

    if (!this.authService.isTokenExpired() && roles.some(rol => allowedRoles.includes(rol))) {
      return true;
    }

    this.router.navigate(['accounts/login']);
    localStorage.clear();
    return false;
  }
}
