import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserType } from '../Enums/user-type';
import { UserView } from '../view-models/account/user-view';
import { Rol } from '../view-models/users/role';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
  ) { }

  public getUsers(orgazinationId: number) {
    return this.http.get<UserView[]>(`${this.baseUrl}api/users/${orgazinationId}`);
  }

  public createUsers(user: UserView) {
    return this.http.post<UserView[]>(`${this.baseUrl}api/users`, user).toPromise();
  }

  getRolesByUserType(userType: UserType) {
    return this.http.get<Rol[]>(`${this.baseUrl}api/users/types/${userType}/roles`).toPromise();
  }

  public updateUsers(user: UserView) {
    return this.http.put<UserView[]>(`${this.baseUrl}api/users`, user);
  }

  public getUserById(userId: number) {
    return this.http.get<UserView>(`${this.baseUrl}api/users/get/${userId}`);
  }

  public getUserRolesById(userId: number) {
    return this.http.get<number[]>(`${this.baseUrl}api/users/${userId}/roles`).toPromise();
  }
}
