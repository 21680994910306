import { CommentsComponent } from './../comments/comments.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KeysPipe } from './pipes/keys.pipe';
import { ValuePipe } from './pipes/value.pipe';
import { SeparateCompoundWordPipe } from './pipes/separate-compound-word.pipe';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    KeysPipe,
    ValuePipe,
    SeparateCompoundWordPipe,
    CommentsComponent
  ],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    FormsModule,
    TranslateModule.forChild(),
    ReactiveFormsModule
  ],
  exports: [KeysPipe, ValuePipe, SeparateCompoundWordPipe, CommentsComponent]
})
export class SharedModule {}
