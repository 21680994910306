import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { default as es } from '@angular/common/locales/es';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { en_US, NgZorroAntdModule, NZ_I18N } from 'ng-zorro-antd';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { ApprovePendingScreenComponent } from './approve-pending-screen/approve-pending-screen.component';
import { AccountLayoutComponent } from './layouts/account-layout/account-layout.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { NavbarMenuComponent } from './menus/navbar-menu/navbar-menu.component';
import { SidebarMenuComponent } from './menus/sidebar-menu/sidebar-menu.component';
import { AuthService } from './services/auth/auth.service';
import { RoleGuard } from './services/auth/role.guard';
import { TokenInterceptor } from './services/interceptors/token-interceptor';
import { SharedModule } from './shared/shared.module';
import { DatePipe } from '@angular/common';
import { DataService } from './services/data/data.service';

registerLocaleData(es, 'es');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/');
}

export function jwtTokenGetter() {
  return localStorage.getItem('sinac_token');
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    AccountLayoutComponent,
    AppLayoutComponent,
    SidebarMenuComponent,
    NavbarMenuComponent,
    ApprovePendingScreenComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {
      paramsInheritanceStrategy: 'always'
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgZorroAntdModule,
    NoopAnimationsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: en_US
    },
    AuthService,
    RoleGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'it' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe,
    DataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
