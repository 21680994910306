import {
  Pipe, PipeTransform
} from '@angular/core';

/**
 * Separates a given string into his words
 *
 * @export
 * @class SeparateCompoundWordPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'separateCompoundWord'
})
export class SeparateCompoundWordPipe implements PipeTransform {
  transform(value): any {
    const keys = [];
    let count = 0;
    let word = '';
    Object.keys(value).forEach(enumMember => {
      word = '';
      for (let i = 0; i < enumMember.match(/[A-Z][a-z]+/g).length; i++) {
        word += ' ' + enumMember.match(/[A-Z][a-z]+/g)[i];
      }
      keys.push({
        key: count,
        value: word
      });
      count++;
    });
    return keys;
  }
}
