import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent {
  isCollapse = false;

  constructor() { }

  hideSideMenu() {
    if (!this.isCollapse) {
      document.getElementById('sinac-logo').style.display = 'none';
      document.getElementById('main-content').style.width = '95%';
      const sideBar = document.getElementById('side-bar');
      sideBar.style.background = '#00b2a6';
      sideBar.style.width = '100%';
      document.getElementById('side-menu').style.width = '5%';
      const sideTexts = document.getElementsByClassName('side-text');
      const sideIcons = document.getElementsByClassName('side-icon');
      Array.prototype.forEach.call(sideTexts, (sideText) => {
        sideText.style.display = 'none';
      });
      Array.prototype.forEach.call(sideIcons, (sideIcon) => {
        sideIcon.style.width = '100%';
      });
      document.getElementById('navbar').style.width = '95%';
      this.isCollapse = true;
    } else {
      document.getElementById('sinac-logo').style.display = 'inline-block';
      const sideBar = document.getElementById('side-bar');
      sideBar.style.removeProperty('background');
      sideBar.style.width = '100%';
      document.getElementById('side-menu').style.width = '15%';
      const sideTexts = document.getElementsByClassName('side-text');
      const sideIcons = document.getElementsByClassName('side-icon');
      Array.prototype.forEach.call(sideTexts, (sideText) => {
        sideText.style.display = 'flex';
      });
      Array.prototype.forEach.call(sideIcons, (sideIcon) => {
        sideIcon.style.width = '30%';
      });
      document.getElementById('main-content').style.removeProperty('width');
      document.getElementById('navbar').style.removeProperty('width');
      this.isCollapse = false;
    }
  }
}
