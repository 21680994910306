import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserType } from '../../Enums/user-type';
import { AccountService } from '../account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private jwtHelperService: JwtHelperService, private accountService: AccountService) { }

  isTokenExpired(): boolean {
    return this.jwtHelperService.isTokenExpired(this.accountService.getUserProfile().token);
  }

  get decodeToken() {
    const token = this.accountService.getUserProfile().token;
    const decodeToken = this.jwtHelperService.decodeToken(token);
    return decodeToken;
  }

  userProfileExists(): boolean {
    return !!this.accountService.getUserProfile();
  }

  getUserRols(): number[] {
    return this.decodeToken['roles'].split(',').map(rol => Number(rol));
  }

  getUserType(): UserType {
    return this.decodeToken['userType'];
  }

  getUserOrganizationId(): number {
    const base10Number = 10;
    return parseInt(this.decodeToken['organizationId'], base10Number);
  }

  getOrganizationStatus() {
    return this.decodeToken['organizationStatus'];
  }

  getUserName(): string {
    return this.decodeToken['sub'];
  }

  getUserSurname(): string {
    return this.decodeToken['surname'];
  }

  getUserEmail(): string {
    return this.decodeToken['email'];
  }

  getOrganizationName(): string {
    return this.decodeToken['organizationName'];
  }
}
