// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  uploadFilesServiceRoute: 'https://localhost:44398/api/attachments',
  googleCaptchaApiKey: '6LesHrcUAAAAAB4pVen5iw5F6F5XTMKEn2MEkl1m',
  osmInitialZoom: 18,
  osmInitialLatitude: 18.476466668402324,
  osmInitialLongitude: -69.91353750228882,
  limitImagesSizeInKb: 512000,
  limitFileSizeInKb: 10000000,
  allowedDocumentTypes: [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/bmp',
    'application/pdf'
  ],
  allowedImagestTypes: [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/bmp',
  ],
  excelDownloadRoute: 'assets/files/Sample.xlsx',
  letterDownloadRoute: 'assets/files/Carta Solicitud SINAC.docx',
  specialCharsCodes: [33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 47, 46, 64, 168, 63, 61, 60, 62, 59, 58, 94, 95, 126, 124, 128, 125, 123,
    96, 170, 92, 44, 43],
  nameFormatRegExp: '(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9].{1,}',
  complianceAgreementRoute: 'assets/files/Acuerdo de Cumplimiento.txt',
  termsAndConditions: 'assets/files/Contrato de términos y condiciones de uso de SINAC.html',
  sinacLogo: 'assets/images/logo_sinac.png',
  conadisLogo: 'assets/images/logo-conadis.png',
  checkImage: 'assets/images/checked.png',
  cancelImage: 'assets/images/cancel.png',
  introductionTextForEvaluationPdf: 'assets/files/Texto de introduccion para pdf de evaluacion.txt',
  howToReadTextForEvaluationPdf: 'assets/files/Texto de interpretacion de informe para pdf de evaluacion.txt',
  responsabilityLimitation: 'assets/files/Limitacion de responsabilidad.txt',
  introductionTextForProjectPdf: 'assets/files/Texto de introduccion para pdf de proyecto.txt',
  howToReadTextForProjectPdf: 'assets/files/Texto de interpretacion de informe para pdf de proyecto.txt',
  contactPageText: 'assets/files/Informaciones de contacto.txt',
  organizationNameMatchRegex: /ORGANIZATION_NAME/g,
  representativeNameMatchRegex: /REPRESENTATIVE_NAME/g,
  representativeIdentificationMatchRegex: /REPRESENTATIVE_ID/g,
  organizationAddressMatchRegex: /ORGANIZATION_ADDRESS/g,
  projectNameMatchRegex: /PROJECT_NAME/g,
  conadisRepresentativeName: 'Lic. Magino Corporan Lorenzo',
  pdfTitle: 'ACUERDO DE CUMPLIMIENTO PLAN DE ACCESIBILIDAD',
  signaturePart: 'POR ',
  conadis: 'CONADIS'
};
