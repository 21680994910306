import { Component, OnInit } from '@angular/core';
import { OrganizationStatus } from '../../Enums/organization-status.enum';
import { UserRol } from '../../Enums/user-rol.enum';
import { UserType } from '../../Enums/user-type';
import { AuthService } from '../../services/auth/auth.service';
import { SidebarMenuItem } from '../../view-models/sidebar-menu/sidebar-menu-item';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html'
})
export class SidebarMenuComponent implements OnInit {
  displaySidebarMenuItem: SidebarMenuItem[] = [];
  sidebarMenuItem: SidebarMenuItem[] = [
    {
      icon: 'assets/images/icons/dashboard.png',
      title: 'Dashboard',
      link: '/dashboard'
    },
    {
      icon: 'assets/images/icons/user.png',
      title: 'Users',
      link: '/users',
      rols: [
        UserRol.ConadisAdmin
      ]
    },
    {
      icon: 'assets/images/icons/user.png',
      title: 'Users',
      link: '/organization/users',
      rols: [
        UserRol.OrganizationAdmin
      ]
    },
    {
      icon: 'assets/images/icons/space.png',
      title: 'Spaces',
      link: '/spaces',
      rols: [
        UserRol.ConadisSpaces,
        UserRol.OrganizationSpaces
      ]
    },
    {
      icon: 'assets/images/icons/service.png',
      title: 'Services',
      link: '/services',
      rols: [
        UserRol.ConadisServices,
        UserRol.OrganizationServices
      ]
    },
    {
      icon: 'assets/images/icons/organization.png',
      title: 'OrganizationalRecord',
      link: '/organizational-record/complete-record',
      rols: [
        UserRol.OrganizationAdmin,
        UserRol.OrganizationServices,
        UserRol.OrganizationSpaces
      ]
    },
    {
      icon: 'assets/images/icons/request.png',
      title: 'Requests',
      link: '/requests',
      rols: [
        UserRol.ConadisApraiser
      ]
    },
    {
      icon: 'assets/images/icons/normative.png',
      title: 'Normative',
      link: '/normative',
      rols: [
        UserRol.ConadisNormative
      ]
    },
    {
      icon: 'assets/images/icons/project.png',
      title: 'Projects',
      link: '/projects',
      rols: [
        UserRol.OrganizationEvaluator,
        UserRol.OrganizationProject,
        UserRol.ConadisEvaluator,
        UserRol.ConadisProject
      ]
    }
  ];

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.createDisplaySideBarMenu();
  }

  private createDisplaySideBarMenu() {
    const base10Number = 10;
    const currentUserRols = this.authService.getUserRols();
    const userType = parseInt(this.authService.getUserType().toString(), base10Number);
    const organizationStatus = this.authService.getOrganizationStatus();

    this.sidebarMenuItem.forEach(menuItem => {
      let isAllowed = false;
      let isAllowedToAll = false;
      if (menuItem.rols !== undefined) {
        isAllowed = menuItem.rols.some(requiredRol =>
          currentUserRols.includes(requiredRol)
        );
      }

      isAllowedToAll = menuItem.rols === undefined ? true : false;

      if (isAllowed || isAllowedToAll) {
        this.displaySidebarMenuItem.push(menuItem);
      }
    });

    if (userType === UserType.Organization) {
      if (organizationStatus !== OrganizationStatus[OrganizationStatus.Approved]) {
        this.displaySidebarMenuItem = this.sidebarMenuItem.filter(sidebar => sidebar.title === 'OrganizationalRecord');
      }
    }
  }
}
