import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Evaluation } from '../../view-models/evaluation/evaluation';

@Injectable()
export class DataService {
  evaluation = <Evaluation>{};
  private source = new BehaviorSubject<Evaluation>(this.evaluation);
  currentEvaluation = this.source.asObservable();

  constructor() { }

  changeData(evaluation: Evaluation) {
    this.source.next(evaluation)
  }

}
