import { Routes } from '@angular/router';
import { ApprovePendingScreenComponent } from './approve-pending-screen/approve-pending-screen.component';
import { UserRol } from './Enums/user-rol.enum';
import { AccountLayoutComponent } from './layouts/account-layout/account-layout.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { RoleGuard } from './services/auth/role.guard';

export const appRoutes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [RoleGuard],
    data: {
      allowedRoles: [
        UserRol.ConadisAdmin,
        UserRol.ConadisApraiser,
        UserRol.ConadisEvaluator,
        UserRol.ConadisNormative,
        UserRol.ConadisProject,
        UserRol.ConadisServices,
        UserRol.ConadisSpaces,
        UserRol.OrganizationAdmin,
        UserRol.OrganizationEvaluator,
        UserRol.OrganizationProject,
        UserRol.OrganizationServices,
        UserRol.OrganizationSpaces
      ]
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'users',
        loadChildren: './users/users.module#UsersModule'
      },
      {
        path: 'organization/users',
        loadChildren:
          './organization-users/organization-users.module#OrganizationUsersModule'
      },
      {
        path: 'organizational-record',
        loadChildren: './organizational-record/organizational-record.module#OrganizationalRecordModule'
      },
      {
        path: 'spaces',
        loadChildren: './spaces/spaces.module#SpacesModule'
      },
      {
        path: 'services',
        loadChildren: './organization-services/organization-services.module#OrganizationServicesModule'
      },
      {
        path: 'normative',
        loadChildren: './normative/normative.module#NormativeModule'
      },
      {
        path: 'projects',
        loadChildren: './project/project.module#ProjectModule'
      },
      {
        path: 'requests',
        loadChildren: './request/request.module#RequestModule'
      }
    ]
  },
  {
    path: 'accounts',
    component: AccountLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './accounts/accounts.module#AccountsModule'
      },
    ]
  },
  {
    path: 'organization',
    loadChildren: './organization-create/organization-create.module#OrganizationCreateModule'
  },
  {
    path: 'confirmation',
    children: [
      {
        path: '',
        loadChildren: './confirmation/confirmation.module#ConfirmationModule'
      }
    ]
  },
  {
    path: 'approve-pending',
    component: ApprovePendingScreenComponent
  },
  {
    path: '**',
    redirectTo: '/accounts/login',
    pathMatch: 'full'
  }
];
