import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastNotificationService {

  constructor(private notificationService: NzNotificationService,
    private translateService: TranslateService) { }

  success(title: string, description: string): any {
    this.notificationService.success(title, description);
  }

  error(title: string, description: string): any {
    this.notificationService.error(title, description);
  }

  showServiceError(error: any, body?: string) {
    if (error.status === 500) {
      this.notificationService.error(this.translateService.instant('Error'), this.translateService.instant(error.error.ClientKeyMessage));
    } else if (error.error !== undefined) {
      this.notificationService.error(this.translateService.instant('Error'), this.translateService.instant(error.error));
    } else {
      this.notificationService.error(this.translateService.instant('Error'), this.translateService.instant(body));
    }
  }

  warning(tittle: string, description: string) {
    this.notificationService.warning(this.translateService.instant(tittle), this.translateService.instant(description));
  }
}
