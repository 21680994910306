import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { OrganizationStatus } from '../Enums/organization-status.enum';

@Component({
  selector: 'app-approve-pending-screen',
  templateUrl: './approve-pending-screen.component.html',
  styleUrls: ['./approve-pending-screen.component.scss']
})
export class ApprovePendingScreenComponent {

  constructor(private authService: AuthService) { }

  identifyStatus() {
    const organizationStatus = this.authService.getOrganizationStatus();
    if (organizationStatus === OrganizationStatus[OrganizationStatus.PendingOfDocument]) {
      return true;
    }
    return false;
  }
}
