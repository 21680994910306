import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Comment } from './../../view-models/comment/comment';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  addComment(comment: Comment) {
    return this.http.post(`${this.baseUrl}api/comments`, comment);
  }
}
