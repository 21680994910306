import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-navbar-menu',
  templateUrl: './navbar-menu.component.html',
  styleUrls: ['./navbar-menu.component.scss']
})
export class NavbarMenuComponent implements OnInit {
  userName: string;
  userSurname: string;
  avatarText: string;
  avatarColor: string;
  colorCode = '#000000';

  constructor(private router: Router,
    private authService: AuthService) { }

  ngOnInit() {
    this.avatarColor = this.colorCode.replace(/0/g, () => this.getRandomBase16Number());
    this.userName = this.authService.getUserName();
    this.userSurname = this.authService.getUserSurname();
    this.avatarText = this.userName.charAt(0) + this.userSurname.charAt(0);
  }

  private getRandomBase16Number() {
    return (Math.floor(Math.random() * 16)).toString(16);
  }

  onSignOut() {
    localStorage.clear();
    this.router.navigate(['/accounts/login']);
  }
}
