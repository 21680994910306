import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastNotificationService } from '../services/toast-notification.service';
import { EntityType } from './../Enums/entity-type.enum';
import { AuthService } from './../services/auth/auth.service';
import { CommentsService } from './../services/comments/comments.service';
import { UserService } from './../services/user.service';
import { UserView } from './../view-models/account/user-view';
import { Comment } from './../view-models/comment/comment';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnChanges {
  @Input() entity: any;
  @Input() entityType: EntityType;
  @Input() receivers = [];
  @Input() comments: Comment[] = [];
  displayComment: Comment[] = [];
  isLoading = false;
  user: UserView;
  commentForm: FormGroup;
  comment: Comment;
  collapseHeader = 'HideCommentsList';
  organizationNameByEntity = {
    [EntityType.Space]: (comment) => this.getOrganizationName(comment),
    [EntityType.Service]: (comment) => this.getOrganizationName(comment),
    [EntityType.Organization]: () => this.getOrganizationNameForOrganizationComment(),
    [EntityType.AdaptationPlan]: (comment) => this.getOrganizationName(comment)
  };

  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private toastNotificationService: ToastNotificationService,
    private commentService: CommentsService
    ) { }

  ngOnInit() {
    this.buildForm();
    this.getUser();
  }

  ngOnChanges() {
    if (this.entity) {
      let comments = [];
      let emails = [];
      if (this.entity.comments) {
        comments = [...this.entity.comments.map(item => item.comment)];
        const userEmail = this.authService.getUserEmail();
        emails = this.entity.comments.map(item => item.comment).map(sender => sender.user).map(mail => mail.email)
          .filter(email => email !== userEmail);
      }
      this.displayComment = comments.length !== 0 ? comments : this.comments;
      this.displayComment.forEach(comment => {
        const fullUserName = `${comment.user.name} ${comment.user.surname}`;
        const organizationName = this.organizationNameByEntity[this.entityType](comment);
        comment.sender = `(${organizationName}) ${fullUserName}`;
      });
      if (emails.length !== 0) {
        this.receivers = emails;
      }
    }
  }

  buildForm() {
    this.commentForm = this.formBuilder.group({
      comment: ['', Validators.required]
    });
  }

  getUser() {
    const userId = this.authService.decodeToken['userId'];
    this.userService.getUserById(userId).subscribe(user => {
      this.user = user;
    }, error => {
      this.toastNotificationService.showServiceError(error.error, 'Error');
    });
  }

  addComment(): void {
    this.isLoading = true;
    const description = this.commentForm.get('comment').value;
    this.commentForm.get('comment').setValue('');
    this.saveComment(description);
    this.isLoading = false;
  }

  saveComment(description: string) {
    const organizationName = this.authService.decodeToken['organizationName'];
    const sender = `(${organizationName}) ${this.user.name} ${this.user.surname}`;
    this.comment = {
      description: description,
      userId: this.user.id,
      sender: sender,
      dateTime: Date.now(),
      receivers: this.receivers,
      entityId: this.entity.id,
      entityType: this.entityType
    };
    this.displayComment = [...this.displayComment, this.comment];
    this.commentService.addComment(this.comment).subscribe(() => { }, error => {
      this.toastNotificationService.showServiceError(error.error, 'Error');
    });
  }

  disabled(visible: Boolean) {
    if (visible) {
      this.collapseHeader = 'HideCommentsList';
    } else {
      this.collapseHeader = 'DisplayCommentsList';
    }
  }

  getOrganizationName(currentComment: Comment) {
    const organizationName = currentComment.user.organization.name;
    return organizationName;
  }

  getOrganizationNameForOrganizationComment() {
    const organizationName = this.entity.name;
    return organizationName;
  }
}
