import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginRequest } from '../view-models/account/login-request';
import { UserProfile } from '../view-models/account/user-profile';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  tokenKey = 'sinac_token';

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,
    private router: Router) { }

  login(login: LoginRequest): any {
    return this.http.post(`${this.baseUrl}api/accounts/login`, login).toPromise();
  }

  setToken(data): void {
    localStorage.setItem(this.tokenKey, JSON.stringify(data));
  }

  getUserProfile(): UserProfile {
    return JSON.parse(localStorage.getItem(this.tokenKey)) as UserProfile;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['accounts/login']);
  }

  restartPasswordRequest(restartRequest: { email: string }) {
    return this.http.post(`${this.baseUrl}api/accounts/reset-password-request`, restartRequest).toPromise();
  }

  validateResetCode(validateRequest: { code: string }) {
    return this.http.post<boolean>(`${this.baseUrl}api/accounts/validate/reset-code`, validateRequest).toPromise();
  }

  restartPassword(restart: { code: string; newPassword: string }) {
    return this.http.put(`${this.baseUrl}api/accounts/reset-password`, restart).toPromise();
  }

  changePassword(change: { oldPassword: string; newPassword: string }) {
    return this.http.put(`${this.baseUrl}api/accounts/change-password`, change).toPromise();
  }

  activateUser(activate: { userId: string }) {
    return this.http.put(`${this.baseUrl}api/accounts/confirm-email`, activate).toPromise();
  }

  activateUserByChangingPassword(update: { id: number, password: string }) {
    return this.http.put(`${this.baseUrl}api/accounts/update-password`, update).toPromise();
  }
}
