export enum UserRol {
  OrganizationAdmin = 1,
  ConadisAdmin = 2,
  OrganizationSpaces = 3,
  ConadisSpaces = 4,
  OrganizationServices = 5,
  ConadisServices = 6,
  ConadisNormative = 7,
  OrganizationEvaluator = 8,
  ConadisEvaluator = 9,
  OrganizationProject = 10,
  ConadisProject = 11,
  ConadisApraiser = 12
}
